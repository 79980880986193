.trigger {
  display: flex;
  height: 4rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.6rem;
  border: 0.1rem solid hsl(214.3 31.8% 91.4%);
  background-color: hsl(0 0% 100%);
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.trigger::placeholder {
  color: hsl(215.4 16.3% 46.9%);

}

.trigger:focus {
  outline: none;
}

.trigger:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.trigger > span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.triggerIcon {
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0.5;
}

.triggerBudgetIcon {
  width: 2rem;
  height: 2rem;
  color: #27c7ff;
}

.scrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  padding: 0.4rem 0;
}

.scrollButton svg {
  width: 1.6rem;
  height: 1.6rem;
}

.content {
  position: relative;
  z-index: 50;
  max-height: 38.4rem;
  min-width: 12.8rem;
  overflow: hidden;
  border-radius: 0.6rem;
  border: 0.1rem solid hsl(214.3 31.8% 91.4%);
  background-color: hsl(0 0% 100%);
  color: hsl(222.2 84% 4.9%);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.contentPopper[data-side="bottom"] {
  transform: translateY(0.4rem);
}


.contentPopper[data-side="left"] {
  transform: translateX(-0.4rem);
}

.contentPopper[data-side="right"] {
  transform: translateX(0.4rem);
}

.contentPopper[data-side="top"] {
  transform: translateY(-0.4rem);
}

.viewport {
  padding: 0.4rem;
}

.viewportPopper {
  height: var(--radix-select-trigger-height);
  width: 100%;
  min-width: var(--radix-select-trigger-width);
}


.label {
  padding: 0.7rem 0;
  padding-left: 3.2rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
}

.item {
  position: relative;
  display: flex;
  width: 100%;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.2rem;
  padding: 0.7rem 0;
  padding-left: 3.2rem;
  padding-right: 0.8rem;
  font-size: 1.4rem;
  line-height: 2rem;
  outline: none;
}

.item > span {
  position: absolute;
  left: 0.8rem;
  display: flex;
  height: 1.4rem;
  width: 1.4rem;
  align-items: center;
  justify-content: center;
}

.item:focus {
  background-color: hsl(210 40% 96.1%);
  color: hsl(222.2 47.4% 11.2%);
}

.item[data-disabled="true"] {
  pointer-events: none;
  opacity: 0.5;
}

.itemIcon {
  width: 1.6rem;
  height: 1.6rem;
}

.separator {
  margin: 0.4rem -0.4rem;
  height: 0.1rem;
  background-color: hsl(210 40% 96.1%);
}
