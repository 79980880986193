.base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: medium;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.base:hover {
  background-color:  hsl(210 40% 96.1%);
  color: hsl(215.4 16.3% 46.9%);
}

.base:focus-visible {
  outline: none;
}

.base:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.base[data-state='on'] {
  background-color: #fff;
}

.default {
  background-color: transparent;
  border: 0.1rem solid transparent;
  border-radius: 0.8rem;
}

.default[data-state='on'] {
  border: 0.1rem solid #27c7ff;
}

.secondary {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.8rem;
  border-width: 0.2rem;
}

.secondary:hover {
  background-color: #27272a;
}

.secondary[data-state='on'] {
  border: 0.1rem solid #52525b;
  background-color: transparent;
}

.outline {
  border-width: 0.1rem;
  border-color: hsl(214.3 31.8% 91.4%);
  background-color: transparent;
}

.outline:hover {
  background-color: #fff;
  color: hsl(222.2 47.4% 11.2%);
}

.sizeDefault {
  height: 4rem;
  padding: 0 1.2rem;
}

.sizeSm {
  height: 3.6rem;
  padding: 0 1rem;
}

.sizeLg {
  height: 4.4rem;
  padding: 0 2rem;
}
