.base {
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  border-width: 0.1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.base:focus {
  outline: none;
  box-shadow: 0 0 0 0 calc(2px + 0) rgb(59 130 246 / .5);
  box-shadow: 0 0 0 2px rgb(59 130 246 / .5), #000;
}

.default {
  border-color: transparent;
  background-color: hsl(222.2 47.4% 11.2%);
  color: hsl(210 40% 98%);
}

.default:hover {
  background-color: hsl(222.2 47.4% 11.2%);
  opacity: 0.8;
}

.secondary {
  border-color: transparent;
  background-color: hsl(210 40% 96.1%);
  color: hsl(222.2 47.4% 11.2%);
}

.secondary:hover {
  background-color: hsl(210 40% 96.1%);
  opacity: 0.8;
}

.destructive {
  border-color: transparent;
  background-color: hsl(0 84.2% 60.2%);
  color: hsl(210 40% 98%);
}

.destructive:hover {
  background-color: hsl(0 84.2% 60.2%);
  opacity: 0.8;
}

.outline {
  color: hsl(222.2 84% 4.9%)
}
