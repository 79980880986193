.base {
  display: flex;
  min-height: 8rem;
  width: 100%;
  border-width: 0.1rem;
  border-color: hsl(214.3 31.8% 91.4%);
  border-radius: 0.6rem;
  background-color: hsl(0 0% 100%);
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.base::placeholder {
  color: hsl(215.4 16.3% 46.9%);
}

.base:focus-visible {
  outline: none;
}

.base:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
