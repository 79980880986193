.base {
/*disabled:pointer-events-none disabled:opacity-50*/
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: medium;
  box-shadow: 0 0 0 0 rgb(59 130 246 / .5), 0 0 #000;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  cursor: pointer;
}

.base:focus-visible {
  outline: none;
  box-shadow: 0 0 0 0 calc(2px + 0) rgb(59 130 246 / .5);
  box-shadow: 0 0 0 2px rgb(59 130 246 / .5), #000;
}

.base:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.default {
  background-color: hsl(222.2 47.4% 11.2%);
  color: hsl(210 40% 98%)
}

.default:hover {
  background-color: hsl(222.2 47.4% 11.2%);
  opacity: 0.9;
}

.destructive {
  background-color: hsl(0 84.2% 60.2%);
  color: hsl(210 40% 98%)
}

.destructive:hover {
  background-color: hsl(0 84.2% 60.2%);
  opacity: 0.9;
}

.outline {
  /* "border border-input bg-background hover:bg-accent hover:text-accent-foreground" */
  border-width: 0.1rem;
  border-color: hsl(214.3 31.8% 91.4%);
  background-color: hsl(0 0% 100%);
}

.outline:hover {
  background-color: hsl(210 40% 96.1%);
  color: hsl(222.2 47.4% 11.2%);
}

.secondary {
  /* "bg-secondary text-secondary-foreground hover:bg-secondary/80" */
  background-color: hsl(210 40% 96.1%);
  color: hsl(222.2 47.4% 11.2%);
}

.secondary:hover {
  background-color: hsl(210 40% 96.1%);
  opacity: 0.8;
}

.ghost {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: medium;
  box-shadow: 0 0 0 0 rgb(59 130 246 / .5), 0 0 #000;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  cursor: pointer;
}

.ghost:hover {
  background-color: hsl(210 40% 96.1%);
  color: hsl(222.2 47.4% 11.2%);
}

.link {
  color: hsl(222.2 47.4% 11.2%);
  text-underline-offset: 0.4rem;
}

.link:hover {
  text-decoration: underline;
}

.sizeDefault {
  height: 4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.sizeSm {
  height: 3.6rem;
  border-radius: 0.6rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.sizeLg {
  height: 4.4rem;
  border-radius: 0.6rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
}

.sizeIcon {
  height: 4rem;
  width: 4rem;
}
