.base {
  flex-shrink: 0;
  background-color: hsl(214.3 31.8% 91.4%);
}

.horizontal {
  height: 0.1rem;
  width: 100%;
}

.vertical {
  /* "h-full w-[1px]" */
  height: 100%;
  width: 0.1rem;
}
