.base {
 display: flex;
 height: 4rem;
 width: 100%;
 border-radius: 0.6rem;
 border-width: 0.1rem;
 border-color: #d1d5db;
 background-color: #f3f4f6;
 padding: 0.8rem 1.2rem;
 font-size: 1.4rem;
 line-height: 2rem;
 box-shadow: 0 0 0 2px hsl(0 0% 100%), #000;
}

.base::placeholder {
  color: hsl(215.4 16.3% 46.9%);
}

.base[type="file"] {
  border-width: 0;
  background: transparent;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
}

.base:focus-visible {
  outline: none;
}

.base:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
