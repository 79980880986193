.relative {
  position: relative;
}

.hidden {
  overflow: hidden;
}

.flex {
  display: flex;
}

.ml {
  margin-left: -1.6rem;
}

.mtCol {
  margin-top: -1.6rem;
  flex-direction: column;
}

.carouselItem {
  min-width: 0;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100%;
}

.carouselPrevNext {
  position: absolute;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 9999px;
}

.carouselPrevHorizontal {
  left: -4.8rem;
  top: 50%;
  transform: translateY(-50%);
}

.carouselPrevVertical {
  top: -4.8rem;
  left: 50%;
  transform: translateX(-50%), rotate(90deg);
}

.carouselArrow {
  height: 1.6rem;
  width: 1.6rem;
}

.carouselSpan {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.carouselNextHorizontal {
  right: -4.8rem;
  top: 50%;
  transform: translateY(-50%);
}

.carouselNextVertical {
  bottom: -4.8rem;
  left: 50%;
  transform: translateX(-50%), rotate(90deg);
}
