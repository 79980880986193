.padding {
  padding: 1.2rem;
}

.months {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media (min-width: 640px) {
    flex-direction: row;
  }
}

.month > * {
  margin-top: 1.6rem;
}

.caption {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.4rem;
  position: relative;
}

.captionLabel {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
}

.nav {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.navButton {
  background-color: transparent;
  padding: 0;
  opacity: 0.5;
  height: 2.8rem;
  width: 2.8rem;
}

.navButtonDark {
  background-color: transparent;
  padding: 0;
  opacity: 0.5;
  height: 2.8rem;
  width: 2.8rem;
  border-width: 0.1rem !important;
  border-color: #ffffff !important;
  color: #ffffff;
  background-color: transparent;
}

.navButtonDark:hover {
  background-color: hsl(217.2 32.6% 17.5%);
  color: hsl(210 40% 98%);
}

.navButton:hover {
  opacity: 1;
}

.navButtonPrevious {
  position: absolute;
  left: 0.4rem;
}

.navButtonNext {
  position: absolute;
  right: 0.4rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table > * {
  margin-top: 0.4rem;
}

.headRow {
  display: flex;
}

.headCell {
  color: hsl(215.4 16.3% 46.9%);
  width: 3.6rem;
  border-radius: 0.6rem;
  font-weight: 400;
  font-size: 1.28rem;
}

.row {
  display: flex;
  width: 100%;
  margin-top: 0.8rem;
}

.cell {
  width: 3.6rem;
  height: 3.6rem;
  text-align: center;
  border-radius: 1.2rem;
  padding: 0;
  position: relative;
  font-size: 1.4rem;
  line-height: 2rem;
}

.cell:focus-within {
  position: relative;
  z-index: 20;
}

.cell:has([aria-selected]) {
  background-color: hsl(210 40% 96.1%);
}

.cell:has([aria-selected]).day-range-end {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.cell:has([aria-selected]).day-outside {
  background-color: hsl(210 40% 96.1%);
  opacity: 0.5;
}

.day {
  width: 3.6rem;
  height: 3.6rem;
  padding: 0;
  font-weight: 400;
  background-color: transparent;
}

.dayDark {
  width: 3.6rem;
  height: 3.6rem;
  padding: 0;
  font-weight: 400;
  background-color: transparent;
  color: #ffffff;
}

.dayDark:hover {
  background-color: #ffffff;
  color: hsl(222.2 47.4% 11.2%);
}

.day[aria-selected='true'] {
  opacity: 1;
}

.daySelected {
  background-color: hsl(222.2 47.4% 11.2%);
  color: hsl(210 40% 98%);
}

.daySelected:hover {
  background-color: hsl(222.2 47.4% 11.2%);
  color: hsl(210 40% 98%);
}

.daySelected:focus {
  background-color: hsl(222.2 47.4% 11.2%);
  color: hsl(210 40% 98%);
}

.daySelectedDark {
  background-color: #ffffff;
  color: #27272a;
}

.daySelectedDark:focus {
  background-color: #ffffff;
  color: #27272a;
}

.dayToday {
  background-color: hsl(210 40% 96.1%);
  color: hsl(222.2 47.4% 11.2%);
}

.dayTodayDark {
  background-color: #27272a;
  color: #f3f4f6;
}

.dayOutside {
  opacity: 0.5;
  color: hsl(215.4 16.3% 46.9%);
}

.dayOutside[aria-selected='true'] {
  color: hsl(215.4 16.3% 46.9%);
  opacity: 0.3;
  background-color: hsl(210 40% 96.1% / 50%);
}

.dayDisabled {
  color: hsl(215.4 16.3% 46.9%);
  opacity: 0.5;
}

.dayRangeMiddle[aria-selected='true'] {
  background-color: hsl(210 40% 96.1%);
  color: hsl(222.2 47.4% 11.2);
}

.dayHidden {
  visibility: hidden;
}

.icon {
  height: 1.6rem;
  width: 1.6rem;
}
