.wrapper {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
  }
  
  .dropzone {
    border-width: 2px;
    padding-bottom: 50px;
    padding-top: 50px;
    border-radius: 16px;
  
  }
  
  .icon {
    color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
  }
  
  .control {
    position: absolute;
    height: 48px;
    left: calc(50% - 100px);
    bottom: -20px;
    background: #27c7ff;
    color: var(--white-grayscale, #FFF);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    letter-spacing: 0.14px;
    text-transform: uppercase;
    transition: ease-in-out all 300ms;
  }
  
  .control:hover{
    background: #eca738;
  }
  
  .files{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .file{
    color: var(--yellow-brand-primary, #27c7ff);
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.18px;
  display: flex;
  align-items: center;
  gap: 5px;
  }
  
  .container{
    display:  flex;
    width: 50%;
    @media screen and (max-width: 420px) {
      width: 100%;
    }
  
  }
  
  .info_wrapper{
    display: flex;
    justify-content: space-between;
  }
  
  .label{
    color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
  }
  
  
  .selected{
  border-radius: 16px;
  border: 2px dashed var(--yellow-brand-primary, #27c7ff);
  background: var(--yellow-3-support, #E9F9FF);
  color: var(--yellow-brand-primary, #27c7ff);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
    }
  