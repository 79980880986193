.base {
  font-weight: 500;
  line-height: 1;
  font-size: 1.4rem;
}

*:disabled + .base {
  cursor: not-allowed;
  opacity: 0.7;
}
