.dropdown {
  width: 200px !important;
}

.label {
  color: var(--grey-1-grayscale, #404040);

  width: 180px;

  /* txt reg  - 16px */
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.16px;
}

.button {
  width: 220px;
  height: 48px;

  padding: 0 32px;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  background: #27c7ff;
  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
  border: 2px solid var(--yellow-brand-primary, #27c7ff);

  transition: all 0.4s;

  & > div {
    justify-content: space-between;
  }

  &:hover {
    background: #27c7ff;
    opacity: 0.8;
  }
}
