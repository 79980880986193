.select-status
.mantine-Input-input{
  display: flex;
height: 54px;
padding: 12.19px 8px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--yellow-brand-primary, #27c7ff);

color: var(--grey-1-grayscale, #404040);
font-family: Neometric;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-left: 4rem;
}

.mantine-InputWrapper-label{

    max-width: 400px;
    text-overflow:  ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.select-status .mantine-InputWrapper-label{
  color: var(--grey-1-grayscale, #404040);
font-family: Neometric;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 1rem;
}

.select-status .mantine-Input-icon{
  width: 4.125rem;
}

