.card {
  border-radius: 0.8rem;
  border-width: 0.1rem;
  background-color: hsl(0 0% 100%);
  color: hsl(222.2 84% 4.9%);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.cardHeader {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 2.4rem;
}

.cardTitle {
  font-weight: 600;
  font-size: 2.4rem; /* 24px */
  line-height: 1;
  letter-spacing: -0.025em;
}

.cardDescription {
  font-size: 1.4rem; /* 14px */
  line-height: 2rem; /* 20px */
  color: hsl(215.4 16.3% 46.9%);
}

.cardContent {
  padding: 2.4rem;
  padding-top: 0px;
}

.cardFooter {
  display: flex;
  align-items: center;
  padding: 2.4rem;
  padding-top: 0px;
}
